'use client';
import { usePathname } from 'next/navigation';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import DriftButton from '~/components/atoms/Buttons/Ctas/DriftButton/DriftButton';
import useUIStore from '~/state/ui';
import { cn, killTimeline } from '~/utils';
import useFocusTrap from '~/utils/useFocusTrap';

import mainNavStyles from '../Navigation.module.css';
import useNavState from '../Navigation.state';
import NavSignin from '../NavSignin/NavSignin';
import {
  closeMobileNavAnimation,
  openMobileNavAnimation,
} from './NavMobile.animations';
import styles from './NavMobile.module.css';
import { NavMobileProps } from './NavMobile.types';
import { ToggleButton } from './ToggleButton';

const NavMobile = ({
  children,
  signupLink,
  freeTrialLink,
  contactSalesButton,
  contactUsLink,
  isHidden,
  navGroups,
}: NavMobileProps) => {
  const pathname = usePathname();
  const [
    mobileNavOpen,
    setMobileNavOpen,
    setCurrentOpenedSubNav,
    $mobileMainNavCtaItems,

    setMobileBottomLinksWrapper,
    isMobileNav,
  ] = useNavState(
    (state) => [
      state.mobileNavOpen,
      state.setMobileNavOpen,
      state.setCurrentOpenedSubNav,
      state.$mobileMainNavCtaItems,
      state.setMobileBottomLinksWrapper,
      state.isMobileNav,
    ],
    shallow,
  );

  const [breakpoint, setIsScrollLocked] = useUIStore(
    (state) => [state.breakpoint, state.setIsScrollLocked],
    shallow,
  );

  const $navMobile = useRef<HTMLDivElement>(null);

  const $wrapper = useRef<HTMLDivElement>(null);
  const $mainLinksWrapper = useRef<HTMLUListElement>(null);
  const $bottomLinksWrapper = useRef<HTMLDivElement>(null);
  const animation = useRef<GSAPTimeline>();

  useEffect(() => {
    setMobileBottomLinksWrapper($bottomLinksWrapper);
  }, [setMobileBottomLinksWrapper]);

  // on route change
  useEffect(() => {
    // close the mobile nav (without animation) if open
    setMobileNavOpen(false);
  }, [pathname, setMobileNavOpen]);

  const onFocusTrapKeyDown = useFocusTrap($navMobile);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (animation.current?.isActive() || !mobileNavOpen) return;
    if (event.code === 'Escape') {
      setMobileNavOpen(false);
    }

    // Focus trap
    if (onFocusTrapKeyDown) onFocusTrapKeyDown(event);
  };

  useEffect(() => {
    // close nav and clear animation on breakpoint change
    resetAnimation(true);
    setMobileNavOpen(false);
  }, [breakpoint, setMobileNavOpen]);

  function resetAnimation(clearProps: boolean) {
    if (animation.current?.isActive())
      killTimeline(animation.current, clearProps);
  }

  const toggleNav = useCallback(() => {
    if (mobileNavOpen) {
      setCurrentOpenedSubNav(null);
    }
    setMobileNavOpen(!mobileNavOpen);
  }, [mobileNavOpen, setMobileNavOpen]);

  useEffect(() => {
    if (!isMobileNav) return;
    resetAnimation(false);

    if (mobileNavOpen) {
      if ($mobileMainNavCtaItems) {
        setIsScrollLocked(true);
        $wrapper.current?.focus();

        for (const [, group] of Object.entries(navGroups.current)) {
          group.closeSubNavAndResetAnimation();
        }

        animation.current = openMobileNavAnimation({
          $wrapper,
          $mobileMainNavCtaItems,
          $bottomLinksWrapper,
        });
      }
    } else {
      if ($mobileMainNavCtaItems) {
        setIsScrollLocked(false);
        animation.current = closeMobileNavAnimation({
          $wrapper,
          $mobileMainNavCtaItems,
        });
      }
    }

    animation.current?.play();
  }, [
    mobileNavOpen,
    breakpoint,
    setIsScrollLocked,
    setCurrentOpenedSubNav,
    isMobileNav,
    $mobileMainNavCtaItems,
    navGroups,
  ]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={cn(styles.container, isHidden && styles.isHidden)}
      ref={$navMobile}
      role="dialog"
      aria-label="Mobile navigation bar"
      onKeyDown={handleKeyDown}
    >
      <div className={styles.navMobileRight}>
        {signupLink && (
          <NavSignin
            type="button"
            className={styles.textLink}
            buttonVariant="text"
            signinLink={signupLink}
          />
        )}

        <ToggleButton onClick={toggleNav} />
      </div>
      <div className={cn(styles.navMobile, mobileNavOpen && styles.open)}>
        <div className={styles.navMobileWrapper} ref={$wrapper}>
          <div className={styles.navMobileContent}>
            <div className={styles.navMobileItems}>
              <ul className={styles.navMobileMainLinks} ref={$mainLinksWrapper}>
                {children}
              </ul>

              <div
                className={styles.navMobileBottomLinks}
                ref={$bottomLinksWrapper}
              >
                {contactUsLink && (
                  <Button
                    buttonVariant="primary"
                    buttonColorScheme="glass"
                    className={styles.bottomButton}
                    to={contactUsLink}
                  >
                    {contactUsLink?.label}
                  </Button>
                )}
                {contactSalesButton && (
                  <DriftButton
                    {...contactSalesButton.button}
                    buttonVariant="primary"
                    buttonColorScheme="glass"
                    className={styles.bottomButton}
                  />
                )}
                {freeTrialLink && (
                  <Button
                    to={freeTrialLink}
                    buttonVariant="primary"
                    buttonColorScheme="white"
                    className={styles.bottomButton}
                  >
                    {freeTrialLink?.label}
                  </Button>
                )}
              </div>
            </div>
          </div>

          <figure
            className={cn(mainNavStyles.backdrop, styles.backdropMobile)}
          />
        </div>
      </div>
    </div>
  );
};

export default NavMobile;
