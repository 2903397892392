'use client';
import { usePathname } from 'next/navigation';
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { shallow } from 'zustand/shallow';

import SvgCaret from '~/assets/svg/caret.svg';
import { cn } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';
import { getNumberFromString } from '~/utils/Strings';
import useDelayedAction from '~/utils/useDelayedAction';
import useFocusTrap from '~/utils/useFocusTrap';

import mainNavigationStyles from '../Navigation.module.css';
import useNavState from '../Navigation.state';
import { RESET_ACTIVE_DELAY } from '../Navigation.types';
import NavItem from '../NavItem/NavItem';
import useSubNav from '../utils/useSubNav';
import styles from './NavGroup.module.css';
import { ForwardedNavGroupRef, NavGroupProps } from './NavGroup.types';

const minRows = getNumberFromString(styles.minRows) || 1;

const NavGroup = (
  {
    navGroup,
    id,
    className,
    isActive,
    isReducedNav,
    renderedInMobileNav,
    tallestSubNavWrapperHeight,
    onMouseOver,
    onMouseOut,
    onClick,
  }: NavGroupProps,
  ref: ForwardedRef<ForwardedNavGroupRef>,
) => {
  const $el = useRef<HTMLDivElement>(null);
  const $subNavWrapper = useRef<HTMLDivElement>(null);
  const $mobileSubNavLinks = useRef<HTMLElement[]>([]);
  const $desktopSubNavLinkContainers = useRef<HTMLElement[][]>(
    [...Array(navGroup.group.length).keys()].map(() => []),
  );

  const $subCategoryEyebrows = useRef<HTMLHeadingElement[]>([]);

  const $groupTriggerEl = useRef<HTMLButtonElement>(null);

  // desktop only - the main category header in the subnav
  const $categoryHeader = useRef<HTMLHeadingElement>(null);

  const pathname = usePathname();

  const [setSubNavActiveStateDelay, clearSubNavActiveStateDelay] =
    useDelayedAction(RESET_ACTIVE_DELAY);

  const [
    activeSubNavItem,
    setActiveSubNavItem,
    setIsHoveringSubNavItem,
    currentOpenedSubNav,
    currentGroup,
    isMobileNav,
    currentPageSubNavId,
  ] = useNavState(
    (state) => [
      state.activeSubNavItem,
      state.setActiveSubNavItem,
      state.setIsHoveringSubNavItem,
      state.currentOpenedSubNav,
      state.currentGroup,
      state.isMobileNav,
      state.currentPageSubNavId,
      state.isHoveringMainNavItem,
      state.isHoveringSubNavItem,
    ],
    shallow,
  );

  const {
    showOrHideSubNav,
    openSubNav,
    closeSubNav,
    hidePreviousSubNavLinks,
    showNextSubNavLinks,
    swapSubNav,
    closeSubNavAndResetAnimation,
  } = useSubNav({
    id,
    $mobileSubNavLinks,
    $desktopSubNavLinkContainers,
    $categoryHeader,
    $subCategoryEyebrows,
    subNavHeight: tallestSubNavWrapperHeight,
  });

  useImperativeHandle(
    ref,
    () => ({
      id,
      $subNavWrapper,
      $groupTriggerEl,
      showOrHideSubNav,
      openSubNav,
      closeSubNav,
      hidePreviousSubNavLinks,
      showNextSubNavLinks,
      swapSubNav,
      closeSubNavAndResetAnimation,
    }),
    [
      id,
      openSubNav,
      closeSubNav,
      showOrHideSubNav,
      hidePreviousSubNavLinks,
      showNextSubNavLinks,
      swapSubNav,
      closeSubNavAndResetAnimation,
    ],
  );

  const onFocusTrapKeyDownMobile = useFocusTrap($subNavWrapper);

  const isGroupPage = currentGroup == id;

  const isOpened = currentOpenedSubNav === id;

  useEffect(() => {
    // clear time of resetting link active state highlight
    clearSubNavActiveStateDelay();
  }, [pathname, clearSubNavActiveStateDelay]);

  useEffect(() => {
    if (isGroupPage && !isMobileNav && !isReducedNav) {
      // if we are on a subnav page, set the current page as active
      if (currentPageSubNavId) setActiveSubNavItem(currentPageSubNavId);
    }
  }, [
    isGroupPage,
    isMobileNav,
    isReducedNav,
    currentPageSubNavId,
    setActiveSubNavItem,
  ]);

  const onMouseOverSubNavItem = (subNavId: string | undefined) => {
    if (isMobileNav) return;
    clearSubNavActiveStateDelay();
    if (subNavId) {
      setActiveSubNavItem(subNavId);
      setIsHoveringSubNavItem(true);
    }
  };

  const onMouseOutSubNavItem = () => {
    if (isMobileNav) return;
    setSubNavActiveStateDelay(() => {
      // when our mouse leave a subnav item
      // set the current subnav item as active, if there is one
      setActiveSubNavItem(currentPageSubNavId || null);
      setIsHoveringSubNavItem(false);
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    // on mobile, focus trap will be on the subnav element
    if (onFocusTrapKeyDownMobile && isOpened && isMobileNav) {
      onFocusTrapKeyDownMobile(event);
    }
  };

  // mobile back button
  const onClickBack = () => {
    closeSubNav();
    $groupTriggerEl.current?.focus();
  };

  let linkIndex = -1;

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      className={cn(styles.navGroup, isOpened && styles.open, className)}
      aria-label={`${navGroup.label} navigation group`}
      ref={$el}
      role="dialog"
      onKeyDown={handleKeyDown}
    >
      {/* Nav group header */}
      <NavItem
        id={id}
        label={navGroup.label}
        icon={<SvgCaret className={styles.navGroupCaret} />}
        isGroupHeader={true}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        onFocus={onMouseOver}
        onBlur={onMouseOut}
        isActive={isActive}
        className={cn(
          mainNavigationStyles.navigationMainItem,
          styles.navGroupTriggerEl,
        )}
        innerClassName={mainNavigationStyles.navigationMainItemInner}
        ref={$groupTriggerEl}
      />

      {/* Sub nav bar */}
      <div
        className={cn(
          styles.navGroupScrollableContainer,
          'navGroupScrollableContainer',
        )}
      >
        <div className={styles.navGroupSubnav} ref={$subNavWrapper}>
          {/* back button / group label for mobile */}
          <h2
            className={cn(styles.navGroupLabel, styles.navGroupLabelMobile)}
            ref={(element) => {
              if (renderedInMobileNav) {
                linkIndex++;
                addToRefArray({
                  element,
                  refArray: $mobileSubNavLinks,
                  index: linkIndex,
                });
              }
            }}
          >
            <button
              onClick={onClickBack}
              tabIndex={isOpened ? 0 : -1}
              aria-hidden={!isOpened}
              className={styles.backButton}
            >
              <SvgCaret className={styles.navGroupCaret} />
              {navGroup.label}
            </button>
          </h2>
          {/* group label for desktop */}
          <h2
            ref={$categoryHeader}
            className={cn(styles.navGroupLabel, styles.navGroupLabelDesktop)}
          >
            {navGroup.label}
          </h2>

          <div className={styles.navGroupSubgrid}>
            {navGroup.group?.map((group, groupIndex) => {
              const totalItems = group.subcategoryLinks.length;
              const splitColumns = totalItems > minRows;
              return (
                <div
                  className={styles.navGroupSubcategory}
                  key={group._key}
                  style={
                    splitColumns
                      ? ({
                          '--max-rows': Math.max(
                            minRows,
                            Math.round(totalItems / 2),
                          ),
                          '--column-span': 2,
                        } as CSSProperties)
                      : {}
                  }
                >
                  {/* subcategory label / eyebrow */}
                  <h3
                    className={cn(styles.navGroupSubcategoryLabel)}
                    ref={(element: HTMLHeadingElement) => {
                      if (renderedInMobileNav) {
                        linkIndex++;
                        addToRefArray({
                          element,
                          refArray: $mobileSubNavLinks,
                          index: linkIndex,
                        });
                      } else {
                        addToRefArray({
                          element,
                          refArray: $subCategoryEyebrows,
                          index: groupIndex,
                        });
                      }
                    }}
                  >
                    {group.subcategoryLabel}
                  </h3>

                  <ol className={styles.navGroupSubcategoryList}>
                    {group.subcategoryLinks.map((link, index) => {
                      return (
                        <li
                          key={`${link._key}`}
                          className={styles.navGroupSubcategoryListItem}
                        >
                          <NavItem
                            id={`${link._key}`}
                            link={link}
                            className={styles.navGroupSubcategoryItemLink}
                            innerClassName={
                              styles.navGroupSubcategoryItemInnerContent
                            }
                            hidden={!isOpened}
                            isActive={activeSubNavItem === link._key}
                            onMouseOver={() => onMouseOverSubNavItem(link._key)}
                            onMouseOut={onMouseOutSubNavItem}
                            onFocus={() => onMouseOverSubNavItem(link._key)}
                            onBlur={onMouseOutSubNavItem}
                            ref={(element: HTMLButtonElement) => {
                              if (renderedInMobileNav) {
                                linkIndex++;
                                addToRefArray({
                                  element,
                                  refArray: $mobileSubNavLinks,
                                  index: linkIndex,
                                });
                              } else {
                                $desktopSubNavLinkContainers.current[
                                  groupIndex
                                ][index] = element;
                              }
                            }}
                          />
                        </li>
                      );
                    })}
                  </ol>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(NavGroup);
