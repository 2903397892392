import { ensureBearerPrefix } from '~/utils/auth/getAccessTokenFromCookies';

type Account = {
  id: string;
  version: number;
};

type FrameAccountsResponse = {
  data: {
    me: {
      accounts: {
        nodes: {
          account: Account;
        }[];
      };
    };
  };
};

export async function getFrameAccounts(accessToken: string) {
  try {
    let ENDPOINT = process.env.GRAPHQL_ENDPOINT;
    if (!ENDPOINT) {
      console.warn('missing endpoint in env file - fallback default value');
      ENDPOINT = 'https://api.frame.io/graphql';
    }
    const fetchResponse = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'apollographql-client-name': 'web-marketing-site',
        'apollographql-client-version':
          process.env.NEXT_APP_RELEASE_VERSION || '',
        'x-gql-op': 'GetAccountsForRouting',

        Authorization: ensureBearerPrefix(accessToken),
      } as const,
      body: JSON.stringify({
        operationName: 'GetAccountsForRouting',
        query: `query GetAccountsForRouting($first: NonNegativeInt = 100) {
          me {
            id
            ... on AuthenticatedUser {
              accounts(
                page: { first: $first }
                order: { direction: ASC, field: DISPLAY_NAME }
              ) {
                nodes {
                  account {
                    id
                    version
                  }
                }
              }
            }
          }
        }`,
      }),
    });
    const data = (await fetchResponse.json()) as FrameAccountsResponse;
    return data.data.me.accounts.nodes.map((node) => node.account);
  } catch (error) {
    console.error('Error fetching accounts', error);
    return [];
  }
}
