import Cookies from 'js-cookie';

export function removeBearerPrefix<T extends string | null | undefined>(
  token: T,
): T extends string ? string : T {
  if (!token) return token as T extends string ? string : T;
  // Handle URL encoded space, case-insensitive
  if (token.toLowerCase().startsWith('bearer%20')) {
    return token.substring('bearer%20'.length) as T extends string ? string : T;
  }
  // Handle regular space and case-insensitive "bearer "
  if (token.toLowerCase().startsWith('bearer ')) {
    return token.substring('bearer '.length) as T extends string ? string : T;
  }
  return token as T extends string ? string : T;
}

/**
 * Ensures the token has a Bearer prefix (used in headers)
 * A generic type is used so that if the invoker passed in a truthy string, the
 * return type is also a string.
 * @param {string} token - The token that may or may not have a Bearer prefix
 * @returns {string} - Token with Bearer prefix
 */
export function ensureBearerPrefix<T extends string | null | undefined>(
  token: T,
): T extends string ? string : T {
  if (!token) return token as T extends string ? string : T;
  if (token.toLowerCase().startsWith('bearer '))
    return token as T extends string ? string : T;
  if (token.toLowerCase().startsWith('bearer%20')) {
    return `Bearer ${removeBearerPrefix(token)}` as T extends string
      ? string
      : T;
  }
  return `Bearer ${token}` as T extends string ? string : T;
}

export const getAccessTokenFromCookies = (): string | undefined => {
  /**
   * Patch for WEB-1829, where we are removing the "Bearer " prefix from the
   * access token in the cookie. Existing sessions will have the "Bearer "
   * prefix until they refresh, so we need to remove it.
   */
  const accessToken = removeBearerPrefix(
    Cookies.get('auth') as string | undefined | null,
  );
  if (!accessToken) return undefined;

  return accessToken;
};
