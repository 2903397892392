import { MouseEventHandler, useCallback } from 'react';

import { EVENTS } from '~/analytics/config';
import useTrackEventCallback from '~/analytics/hooks/useTrackEventCallback';
import Button from '~/components/atoms/Buttons/Ctas/Button/Button';
import { getFrameAccounts } from '~/data/queries/frame';
import UserStore from '~/state/user';
import { getAccessTokenFromCookies } from '~/utils/auth/getAccessTokenFromCookies';

import NavItem from '../NavItem/NavItem';
import { NavSigninProps } from './NavSignin.types';

// Helper function (define outside component or in utils)
async function determineRedirectUrl(
  token: string,
): Promise<string | undefined> {
  let redirectUrl = process.env.V3_APP_REDIRECT_URL;

  const accounts = await getFrameAccounts(token);
  if (accounts.length > 0 && accounts.some(({ version }) => version === 4)) {
    redirectUrl = process.env.V4_APP_REDIRECT_URL || redirectUrl;
  }

  return redirectUrl;
}

const NavSignin = ({
  id = 'signup',
  signinLink,
  className,
  type = 'navItem',
  buttonVariant,
  buttonColorScheme,
}: NavSigninProps) => {
  const trackRedirect = useTrackEventCallback(EVENTS.signinLinkRedirectClicked);

  const onClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> =
    useCallback(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      async (e) => {
        e.preventDefault();

        const token = getAccessTokenFromCookies();
        const { isLoggedIn, pageSessionStartTimestamp } = UserStore.getState();
        let redirectUrl: string | undefined;

        // Determine redirect URL based on user's account version
        if (token && isLoggedIn) {
          UserStore.getState().setIsLoggedIn(true);

          if (process.env.ENABLE_SIGNIN_REDIRECT === 'true') {
            redirectUrl = await determineRedirectUrl(token);
          } else {
            // If user is logged in and we don't want to redirect, we set redirectUrl to V3 App.
            redirectUrl = process.env.V3_APP_REDIRECT_URL;
          }
        }

        // Fall back to the signin link URL if no redirect URL was determined
        const finalUrl =
          redirectUrl ||
          signinLink.url ||
          process.env.SIGN_IN_REDIRECT_URL ||
          '';

        const elapsedTimeSincePageSessionStart =
          Date.now() - pageSessionStartTimestamp;

        const analyticsData = {
          destination: finalUrl,
          logged_in: String(isLoggedIn),
          session_started_at: String(pageSessionStartTimestamp),
          elapsed_time_since_page_session_start: String(
            elapsedTimeSincePageSessionStart,
          ),
        };

        trackRedirect(analyticsData, () => {
          window.location.href = finalUrl;
        });
      },
      [signinLink, trackRedirect],
    );

  if (type === 'button') {
    return (
      <Button
        to={signinLink}
        onClick={onClick}
        className={className}
        buttonVariant={buttonVariant}
        buttonColorScheme={buttonColorScheme}
      >
        {signinLink.label}
      </Button>
    );
  } else {
    return (
      <NavItem
        id={id}
        link={signinLink}
        onNativeClick={onClick}
        className={className}
      />
    );
  }
};

export default NavSignin;
